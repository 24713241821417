/*body {
    background-color: yellow;
}*/

/*//@import '~antd/dist/antd.less';
//@import '~antd/lib/style/index.less';
@import '~antd/lib/style/themes/index.less';
//@import '~antd/lib/style/core/index.less';
@import '~antd/lib/style/mixins/index.less';
@import '~antd/lib/style/core/base.less';
@import '~antd/lib/style/core/iconfont.less';
@import '~antd/lib/style/core/motion.less';
@import '~antd/lib/style/components.less';*/


//@import '~antd/dist/antd.less';



@import '~antd/lib/style/themes/index.less';
@import '~antd/lib/style/mixins/index.less';
@import '~antd/lib/style/core/base.less';
@import '~antd/lib/style/core/global.less';
@import '~antd/lib/style/core/iconfont.less';
@import '~antd/lib/style/core/motion.less';
@import '~antd/lib/style/components.less';

@root-entry-name: default;

@primary-color: #4472c4; //#1890ff; // primary color for all components
@link-color: #4472c4; //#1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 13px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 1); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 4px; // major border radius
@border-color-base:rgba(0, 0, 0, 0.1); // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers


@font-family: var(--main-font);
@line-height-base: 1.6;
// @{html-selector} {
//     font-family: var(--main-font) !important;
// }