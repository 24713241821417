/* stylelint-disable */

body,html{line-height:initial; }
a,a:hover{color:initial;}
ul{margin:initial}
h3 {cursor: default;flex: 1 100%; font-weight: 700; margin: 0 5px 5px;font-size:inherit;color:inherit;}

/**/






.ant-picker-clear{ background:none;}
.ant-picker-focused .ant-picker-clear{ background:none;}





.input-validation-error.ant-select:not(.ant-select-borderless),
.input-validation-error.ant-input-number,
.input-validation-error.ant-picker{ border:none; box-shadow: 0 0 0 2px rgba(240, 80, 80, 0.3);}


.input-validation-error.ant-select:not(.ant-select-borderless) .ant-select-selector,
.input-validation-error.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,

.input-validation-error.ant-input-number,
.input-validation-error.ant-picker{ border:none;}


/*costom table_view*/
.table_view wrap .ant-picker,
.table_view wrap .ant-select,
.table_view wrap .ant-select .ant-select-selector { box-shadow: none; background: transparent; flex: 1; height: 30px !important; line-height: 30px !important; }

.table_view wrap .ant-select-single .ant-select-selector .ant-select-selection-item,
.table_view wrap .ant-select-single .ant-select-selector .ant-select-selection-placeholder { line-height: 30px !important; }

.table_view wrap .ant-select:not(.ant-select-disabled):hover .ant-select-selector { box-shadow: inset 0 0 0 1px var(--outline); }


.table_view wrap .ant-picker:hover,
.table_view wrap .ant-select:hover,
.table_view wrap .ant-selector:hover,
.table_view wrap .ant-select:hover .ant-select-selector,
.table_view wrap .ant-select-focused.ant-picker,
.table_view wrap .ant-select-focused.ant-select .ant-select-selector { box-shadow: none !important; background: transparent; }


.table_view wrap .ant-picker.input-validation-error,
.table_view wrap .ant-select.input-validation-error { border: none; box-shadow: inset 0 0 0 2px rgba(240, 80, 80, 0.3)!important; }

.table_view wrap .ant-picker.input-validation-warning,
.table_view wrap .ant-select.input-validation-warning { border: none; box-shadow: inset 0 0 0 2px rgba(253, 203, 0, 0.3)!important; }







/*table*/
.ant-table {max-height: 100%;overflow: auto; background:none;}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{background: var(--highlight);}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on{color: var(--main-color);}

.ant-table,.ant-table-thead > tr > th{color:inherit;}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{padding:0 0px;border:none;transition:none;background:none!important;}
.ant-table-thead > tr > th{padding:0 0px;font-weight: 600;}


.ant-table-tbody > tr > td.ant-table-row-cell-break-word{padding:0;}

.ant-table-tbody > tr .ant-table{margin:0 !important;}
tr.ant-table-expanded-row > td{padding:0;}
tr.ant-table-expanded-row:hover > td{}

.ant-table-cell .ant-table-thead tr{box-shadow:none;}

.ant-table-cell .ant-table-thead th{}
.ant-table-expand-icon-col {width:40px;}
.ant-table-row-expand-icon{   margin: 0 auto; float: none;}
.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover{color: var(--main-color);}


.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after{box-shadow:none;}




.ant-table-wrapper {height: 100%;}
.ant-spin-nested-loading {height: 100%;}
.ant-spin-container {height: 100%;display: flex; flex-direction: column;}
.ant-table-container {height: 100%;display: flex; flex-direction: column;overflow: hidden;}
.ant-table-header {flex-shrink: 0;}
.ant-table-body {flex-grow: 1;max-height:100%!important;overflow: auto;overflow-y: auto!important; overflow-x: hidden;}
.ant-table-footer{flex-shrink: 0;padding:0 0;}

.scroll_fix .ant-table-body {overflow-x:scroll!important;overflow-x:overlay!important;overflow-y:scroll!important;}

.ant-table-column-sorters{padding:0 10px;}

/*set no hover*/
.ant-table-wrapper.no_hover .ant-table-tbody > tr, .ant-table-wrapper.no_hover .ant-table-tbody > tr:hover{ background: var(--light); color: rgb(0,0,0)!important;}

/*pagination*/
.ant-pagination{flex-shrink: 0; margin: 10px 0; display: flex; justify-content: flex-end;}
.ant-table-pagination.ant-pagination{margin:0;padding:5px;}
.ant-pagination-item{border-radius:0;}
.ant-pagination-options { margin-left:0;display:flex;}
.ant-pagination-options .ant-select { display: flex; }
.ant-pagination .ant-select-selector { background: inherit !important; padding: 0 !important; border-radius: 0 !important; height: 30px !important; border: none !important; box-shadow: 0 0 0 1px var(--outline); }
.ant-pagination .ant-select-selection-item { }


.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link { border-radius: 0 !important; border: none; }


.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next { border: none; margin-right: 5px; font-family: inherit; border-radius: 0 !important; width: 30px; height: 30px; min-width: 0; display: flex; align-items: center; justify-content: center; box-shadow: 0 0 0 1px var(--outline); }

.ant-pagination-item a,
.ant-pagination-prev a,
.ant-pagination-next a, 
.ant-pagination-jump-prev a, 
.ant-pagination-jump-next a{line-height: 30px;min-width:30px;height:30px;border-radius:0!important; display: flex; align-items: center; justify-content: center;}
.ant-pagination .ant-select{margin-right:0;}

.ant-pagination-item.ant-pagination-item-active { box-shadow: 0 0 0 1px var(--main-color); }

.ant-pagination .anticon { display: flex; align-items: center; justify-content: center; }




.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon { opacity: 1; font-size: inherit;color:inherit }
.ant-pagination-item-ellipsis { display: none !important; }

.ant-pagination-jump-prev:hover .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-container .ant-pagination-item-link-icon { color:var(--main-color); }

.ant-pagination-options .ant-select { height: 30px !important; min-height: 30px !important; line-height: 30px !important; }


.anticon-left::after { content: "left" !important; }
.anticon-right::after { content: "right" !important; }
.anticon-double-left::after { content: "full_left" !important; }
.anticon-double-right::after { content: "full_right" !important; }





/**/
/*table-filter*/
.ant-table-filter-column { margin: 0; }
.ant-table-filter-column-title {padding:0 0 0 10px;}
.ant-table-filter-trigger-container {display: flex; align-items: center; justify-content: center; position: relative; }
.ant-table-filter-trigger-container:hover,
.ant-table-filter-trigger-container-open{background:none;}
.ant-table-filter-trigger{color:inherit;width: 30px; height: 30px;margin: 5px; border-radius: 50%;}
.ant-table-filter-trigger:hover,
.ant-table-filter-trigger-container-open .ant-table-filter-trigger{background-image: var(--press-color); color: inherit;}


.ant-dropdown {}
.ant-table-filter-dropdown {display: flex; flex-direction: column; min-width: 0; font-size: inherit; border-radius: 10px; box-shadow: var(--elevation);}
.ant-table-filter-dropdown > div {padding: 10px!important; display: flex; flex-direction: column;}

.ant-table-filter-dropdown .ant-input{width: 100%!important; margin-bottom: 15px!important; font-size: inherit; border: none; border-radius: inherit; background: var(--input-color);}
.ant-table-filter-dropdown .ant-input:focus{border-right-width:0!important; background: none; box-shadow: 0 0 0 1px var(--outline);}

.ant-table-filter-dropdown .ant-space {}
.ant-table-filter-dropdown .ant-space-item {}

.ant-table-filter-dropdown .ant-btn {border-radius: 15px; font-family: Roboto, Arial;font-weight:600; line-height: 30px; height: 30px; display: flex; align-items: center; justify-content: center; min-width: 100px; box-shadow: inset 0 0 0 1.5px;border: none; text-shadow: none; font-size: inherit;}
.ant-table-filter-dropdown .ant-btn .anticon-search {margin: 0 10px 0 0;}
.ant-table-filter-dropdown .ant-btn:hover{background: var(--main-color);color: #fff;box-shadow: none;}
.ant-table-filter-dropdown .ant-btn:active{transform:translateY(1px);}

.ant-table-filter-dropdown .ant-btn.ant-btn-primary{box-shadow:none; background: var(--main-color);}
.ant-table-filter-dropdown .ant-btn.ant-btn-primary:hover,
.ant-table-filter-dropdown .ant-btn.ant-btn-primary:focus {background-image: var(--over-color);}







.ant-form-item {margin:0;}







/*dropdown*/
.ant-select-dropdown { font-family: var(--main-font); line-height: inherit; font-size: inherit; padding: 0; min-width:100px !important; }
.ant-select-dropdown-menu { max-height: 250px; margin-bottom: 0; padding:5px 0; padding-left: 0; overflow: auto; list-style: none; outline: none;}
.ant-dropdown-menu { padding: 0; border-radius:0px; margin: 0; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); box-shadow: var(--elevation); background-color: #fff; }
.list_menu.ant-dropdown-menu{ padding: 0; border-radius: 10px; margin: 0; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); box-shadow: var(--elevation);}


.ant-select-dropdown .ant-empty-image svg{max-width:100%;}
.ant-select-dropdown.custom_width{min-width:90px!important;}



/**/
/*uploader*/
.avatar-uploader { }
.ant-upload-picture-card-wrapper { zoom: 1; display: inline-block; width:auto; margin: 5px;   flex-grow: 0;}
.ant-upload-picture-card-wrapper::before, .ant-upload-picture-card-wrapper::after{display:none;}

.ant-upload.ant-upload-select-picture-card {display: flex; margin: 0; border: none;  width: 80px; height: 80px; background-color: #fafafa;  border: none;  box-shadow: 0 0 0 1px var(--outline); padding: 5px; background: var(--input-color);border-radius:0;flex-shrink:0;}
.ant-upload.ant-upload-select-picture-card:active i.anticon{transform:translateY(1px);}

.large .ant-upload.ant-upload-select-picture-card {width: 640px; height: 640px;}
.large .ant-upload img {max-width: 640px; max-height: 640px;}
.big .ant-upload.ant-upload-select-picture-card {width: 300px; height: 300px;}
.big .ant-upload img {max-width: 300px; max-height: 300px;}

.ant-upload{ color: inherit;}
.ant-upload.ant-upload-select-picture-card > .ant-upload { display: flex;  align-items: center; justify-content: center; width: 100%; height: 100%; padding: 0; color: rgba(0, 0, 0, 0.25);overflow:hidden;position:relative;}

.ant-upload img{display: block; max-height: 160px; max-width: 160px; position: absolute; transform: translateX(-50%) translateY(-50%); top: 50%; left: 50%; min-width: 80px; min-height: 80px;}
.ant-upload input[type='file'] {}
.ant-upload-text{display:none;}
.ant-upload i.anticon{font-size: 20px;display: flex; align-items: center; justify-content: center; color: inherit;}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {position:relative;}

.ant-upload.ant-upload-select-picture-card > .ant-upload .button{position: absolute; background: rgb(255 255 255 / 0.6); color: rgb(0 0 0 / 0.6);}
.ant-upload.ant-upload-select-picture-card > .ant-upload .button:hover {background: rgb(255 255 255 / 0.8); color: rgb(0 0 0 / 0.8);}



.avatar-uploader { position: relative;  width: 80px; height: 80px; display: flex; align-items: center; justify-content: center; flex-shrink: 0; flex-grow: 0; margin: 5px; padding: 5px; box-shadow: inset 0 0 0 1px var(--outline); background: var(--input-color);}
.avatar-uploader > span {display: flex; position: relative; height: 100%; width: 100%; overflow: hidden;}
.avatar-uploader img{display: block; max-height: 160px; max-width: 160px; position: absolute; transform: translateX(-50%) translateY(-50%); top: 50%; left: 50%; min-width: 80px; min-height: 80px;}
.avatar-uploader .button{position: absolute; background: rgb(255 255 255 / 0.6);  color: rgb(0 0 0 / 0.6);}
.avatar-uploader .button:hover{background: rgb(255 255 255 / 0.8);  color: rgb(0 0 0 / 0.8);}

.avatar-uploader.big {width: 300px; height: 300px;}
.avatar-uploader.big img{max-height: 300px; max-width: 300px;}
/**/


/*uploader*/

[schemas] nav .ant-upload.ant-upload-select-picture-card {     background-color: var(--main-color);}
[schemas] nav .ant-upload.ant-upload-select-picture-card > .ant-upload { color: rgb(255, 255, 255);}

/**/








/*tree*/
.ant-tree-list{ color:inherit; font-size: 13px; line-height:inherit; margin: 0; padding:20px 0 20px 10px;}
.ant-tree .ant-tree-treenode{align-items:center;}
.ant-tree .ant-tree-node-content-wrapper{min-height:30px;line-height:30px;display:flex;align-items:center;padding:0 10px;border-radius:0;}

/**/






/*checkbox*/

.ant-checkbox-wrapper{}
.ant-checkbox{}

.ant-checkbox-indeterminate .ant-checkbox-inner { border-color:var(--main-color); }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after { background-color: var(--main-color); }

.ant-checkbox-inner{}
.ant-checkbox-inner::after{    top: 47%;    left: 27%;    width: 3.714286px;    height: 6.142857px;}

.ant-checkbox-checked .ant-checkbox-inner { /*background-color:var(--main-color); border-color: var(--main-color);*/ }
.ant-checkbox-checked .ant-checkbox-inner::after{}


.ant-checkbox-checked::after{border: none;}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner { border-color: var(--main-color); }



tr.drop-over-downward td { border-bottom: 2px dashed var(--main-color); }
tr.drop-over-upward td { border-top: 2px dashed var(--main-color); }


/*preloader*/

view[small][preloader] > .ant-spin-nested-loading {display: block; transform: translateX(-50%) translateY(-50%); top: 50%; left: 50%;opacity: 1;z-index: 2;position:absolute;height:100%;width:100%;}
.toggler > .ant-spin.ant-spin-spinning {position: relative; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;}

div[preloader] { display: flex; align-items: center; justify-content: center; position: fixed; height: 100%; width: 100%; top: 0; left: 0; z-index:99999; background: rgb(47 47 47 / 47%);}



/*.rc-virtual-list-scrollbar { display: block !important;}*/







/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/


.ant-select,
.ant-picker,
.ant-input-number { height: 20px!important; min-height:20px!important; line-height:20px!important; }


/*hard reset*/
    .ant-select .ant-select-selector,
    .ant-picker,
    .ant-input-number { border: none !important; border-radius: inherit; display: flex; height: 100% !important; line-height: inherit; color: inherit; padding: 0 !important; transition: none; -webkit-transition: none; flex: 1 1; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; background: none !important; border-radius: 0 !important; }

.ant-select,
.ant-picker,
.ant-input-number { box-shadow: 0 0 0 1px var(--outline) !important; background: var(--background) !important; font-size: var(--text); }


/*focused*/
.ant-select-focused.ant-select,
.ant-picker-focused.ant-picker,
.ant-input-number-focused.ant-input-number { background-color:transparent;}

.ant-select-focused.ant-select .ant-select-selector,
.ant-picker-focused.ant-picker,
.ant-input-number-focused.ant-input-number { box-shadow:0 0 0 1px var(--outline);}





.anticon svg { display: none; }
.anticon:after { content: ''; width: calc(var(--point)*2); height: calc(var(--point)*2); font-size: calc(var(--point)*2); flex-shrink: 0; display: flex; -moz-font-feature-settings: "liga=1, dlig=1"; -ms-font-feature-settings: "liga", "dlig"; -webkit-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig"; font-variant-ligatures: common-ligatures; text-rendering: optimizeLegibility; font-family: icon; display: flex; display: -ms-flexbox; display: -webkit-flex; align-items: center; -ms-flex-align: center; justify-content: center; -ms-flex-pack: center; font-weight: 400; font-style: normal; user-select: none; text-transform: lowercase; position: relative; z-index: 9; overflow: hidden; text-decoration: none; pointer-events: none; }

.ant-collapse .ant-collapse-item-active .anticon:after { transform: rotate(90deg); }
.anticon.ant-notification-notice-icon svg { display: inline-block; }
/**/


.ant-collapse-item { display: flex; flex-direction: column; }
.ant-collapse > .ant-collapse-item > .ant-collapse-header { display: flex; align-items: center; word-break: break-all; }

.ant-collapse-content { color: #000000; background-color: #fff; border-top: 1px solid rgba(0, 0, 0, 0.1); word-break: break-all; }










/*Drop Down list*/
.ant-select { color: inherit; line-height: inherit; font-size: inherit; display: flex; align-items: center; }

.ant-select-selector {}
    .ant-select-selector:after { }

.ant-select-selection-search { right: 0 !important; left: 0 !important; top: 0 !important; width: 100%; height: 100%; line-height: inherit; margin: 0 !important; padding: 0 !important; }
        .ant-select-selection-search input { height: 100% !important; line-height: inherit; padding-left:var(--point)!important ;}

    .ant-select-selection-item { line-height: inherit !important; padding:0 calc(var(--point)*2) 0 var(--point)!important; }
        .ant-select-selection-item:after{ }

    .ant-select-arrow {}
    .ant-select-arrow .anticon { }
        .ant-select-arrow .anticon::after{}
        .ant-select-arrow .anticon svg { }


 


           /*combobox*/

.ant-select-selection-placeholder { padding: 0 calc(var(--point)*3) 0 var(--point)!important; line-height: inherit!important; }
.ant-select-selection-placeholder:after { }
/**/




/*Multi Select*/
.ant-select.ant-select-multiple{height:auto!important;}
    .ant-select.ant-select-multiple .ant-select-selector { flex-wrap: wrap; align-items: flex-start; }
    .ant-select-multiple .ant-select-selector::after { line-height:inherit;margin:0;}

.ant-select.ant-select-multiple .ant-select-selection-overflow { display: flex; flex-wrap: wrap; flex-grow: 1; padding: calc(var(--point)/4) calc(var(--point)/4) calc(var(--point)/4) calc(var(--point)); /*height:100%;*/ grid-gap: 5px; }
            .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix { min-width: 0; width: inherit; height: inherit; margin: 0; }            
            .ant-select-selection-overflow-item { line-height: initial; min-width: calc(var(--point)*4); /*margin: calc(var(--point)/4);*/ transition: transform .125s; align-self: flex-start; }


.ant-select.ant-select-multiple:active .anticon:after { transform: none; }
.ant-select-selection-overflow-item:hover .ant-select-selection-item-remove:active > .anticon:after { transform: translateY(1px); }

.ant-select-multiple.ant-select-show-search.ant-select .ant-select-selector input{padding-left:0!important;min-width:0!important}

.ant-select-multiple .ant-select-selection-placeholder { left: auto; right: auto; width: 100%; }
.ant-select.ant-select-multiple .ant-select-selection-item { padding: 0 !important; height: calc(var(--point)*1.5); line-height: calc(var(--point)*1.5) !important; border: none; border-radius: 0; box-shadow: 0 0 0 1px var(--main-color); background-color: var(--main-color-light); margin: 0; }
                .ant-select-selection-item-content { display: flex !important; margin-right: 0 !important; flex-grow: 1; text-align: center; align-items: center; padding: 0 0 0 calc(var(--point)/2) !important; }
                .ant-select-selection-item-remove { line-height: inherit !important; display: flex !important; vertical-align: inherit !important; font-size: inherit !important; color: inherit !important; }
                    .ant-select-selection-item-remove .anticon { vertical-align: initial!important; line-height: inherit; }
                    .ant-select-selection-item-remove .anticon svg { display: none; }
                    .ant-select-selection-item-remove .anticon:after { width: calc(var(--point)*1.5); height: calc(var(--point)*1.5); color: inherit; font-size: calc(var(--point)*1.5); }



/**/



/*Picker*/
.ant-picker { flex-direction: column; }

.ant-picker-input { display: flex; align-items: center; height: 100%; flex-direction: column;flex-grow:1; }

    .ant-picker-input input { padding-left: var(--point); min-height: 100%; display: flex; font-size: var(--text); color: inherit; line-height: inherit; border-radius: 0; height: 100%; flex: 1; }
    .ant-picker-input .ant-picker-suffix { margin: 0; color: inherit; flex-shrink: 0; line-height: inherit; display: flex; align-items: center; justify-content: center; position: absolute; top: 50%; transform: translateX(0px) translateY(-50%);right:0; }
            .ant-picker-input .ant-picker-suffix .anticon { }
            .ant-picker-input .ant-picker-suffix .anticon:after { }

            .ant-picker-input .ant-picker-clear { color: inherit; transform: translateX(-30px) translateY(-50%); right: 0; }
                .ant-picker-input .ant-picker-clear .anticon { }
                    .ant-picker-input .ant-picker-clear .anticon:after { color: inherit; }
        /*Date Picker*/
            .ant-picker-input .ant-picker-suffix .anticon.anticon-calendar {}
                .ant-picker-input .ant-picker-suffix .anticon.anticon-calendar:after { }
        /*Time Picker*/
                .ant-picker-input .ant-picker-suffix .anticon.anticon-clock-circle { }
                .ant-picker-input .ant-picker-suffix .anticon.anticon-clock-circle:after { }
/**/





/*Numeric*/
.ant-input-number { }
    .ant-input-number-handler-wrap {width: calc(var(--point)*3); height:100%; border: none;background: none;}

        .ant-input-number-handler { width: 100%; height:50%!important; display: flex; color: inherit; border: none; }
        .ant-input-number-handler-wrap:hover .ant-input-number-handler { height: 50%!important; }
        .ant-input-number-handler:active { transform: translateY(1px); background: none; }

        .ant-input-number-handler .anticon { width: 100%; margin: 0; top: 0; right: 0; height: 100%; border: none; transform: none; color: inherit; position: relative; line-height: initial; font-size: inherit !important;display: flex; align-items: center; justify-content: center; }
            .ant-input-number-handler .anticon svg {display:none;}
            .ant-input-number-handler .anticon:after { height:100%; }




        .ant-input-number-handler.ant-input-number-handler-up { height: 50%!important; }
        .ant-input-number-handler ant-input-number-handler-down{}
            .ant-input-number-handler-up .anticon svg { }
            .ant-input-number-handler-up .anticon:after { }

        .ant-input-number-handler-down:hover{height:50%!important;}
        .ant-input-number-handler-down .anticon svg { }
            .ant-input-number-handler-down .anticon:after { }


    .ant-input-number-input-wrap {flex-grow:1;height:100%;}
        .ant-input-number-input-wrap input { height: 100%; width: 100%; line-height: inherit; padding-left: var(--point); }
/**/



.form_fields > .ant-time-picker,
.form_fields > .ant-input-number,
.form_fields > .ant-calendar-picker,
.form_fields > .ant-select,
.form_fields > .ant-picker { margin: 5px; flex-grow: 1; }
.form_fields .ant-select { width: calc(100% - 10px); }




/*icons override*/


.ant-select-arrow,
.ant-select-clear { transform: none; transform: translateX(0px); height: auto; width: auto; margin: 0; top: auto; left: auto; right:0px; color: inherit; display: flex; flex-shrink: 0; background: inherit !important; font-size: inherit; }

.ant-select-clear{transform:translateX(-30px);}
.ant-select-clear:hover { color: inherit !important;}
/*.ant-select:hover .ant-select-clear {z-index: 9;}*/






.anticon-close-circle:after { content: "close"; }
.anticon-search:after { content: "search"; }
.anticon-calendar:after { content: "calendar"; }
.anticon-clock-circle:after { content: "clock"; }
.anticon-check:after { content: "done"; }

.anticon-up:after { content: "up"; }
.anticon-down:after { content: "down"; }
.anticon-close:after { content: "close" }
.anticon-plus:after { content: "plus" }

.close-circle:after { content: "close"; }


/*.ant-select .anticon-down:after { content: "more"; }*/





/*---!!!icons override!!!--*/
.ant-picker-input > input ~ .ant-picker-suffix .anticon-clock-circle:after { content: "clock"; }
.ant-picker .ant-picker-input:hover > input[value=""] ~ .ant-picker-suffix .anticon-clock-circle:after { content: "clock"; }

/*.ant-picker-input > input ~ .ant-picker-suffix .anticon-calendar:after { }
.ant-picker .ant-picker-input:hover > input[value=""] ~ .ant-picker-suffix .anticon-calendar:after { content: "calendar"; }

.ant-picker .ant-picker-input:hover .ant-picker-suffix .anticon:after { content: "close"; }

.ant-select.ant-select-allow-clear:hover .ant-select-arrow .anticon:after { content: "close";}*/



.ant-picker .ant-picker-input:hover .ant-picker-clear:active .anticon:after,
.ant-select:active .anticon:after { transform: translateY(1px); }



.ant-picker-suffix,
.ant-select-arrow{}
.ant-picker-clear{}





/*---*/


/*.ant-picker-input .ant-picker-clear { right: 2px; color: inherit; background: var(--ant-input-color); }
.ant-select.ant-select-allow-clear .ant-select-clear { background: var(--ant-input-color); }

cont > .ant-picker-input .ant-picker-clear { right: 2px; color: inherit; background: var(--background); }
cont > .ant-select.ant-select-allow-clear .ant-select-clear { background: var(--background); }*/





/*material override*/
cont > .ant-select,
cont > .ant-select .ant-select-selector,
cont > .ant-picker,
cont > .ant-input-number { height: 100% !important; width: 100%; line-height: inherit !important; background: none !important; border: none !important; box-shadow: none !important; padding:0!important; min-height: 0; }


cont > .ant-select.ant-select-multiple { height: auto !important; min-height: var(--material-point) !important; line-height: var(--material-point) !important; }
cont .ant-select.ant-select-multiple .ant-select-selector { min-height: var(--material-point) !important; }
cont .ant-select.ant-select-multiple .ant-select-selection-overflow { min-height: var(--material-point) !important; height: 100%; padding-left: calc(var(--point)*1.5) !important; padding-top: calc(var(--point)*1) !important; padding-bottom: calc(var(--point)*1) !important; }


cont .ant-select-focused ~ decor,
cont .ant-picker-focused ~ decor,
cont .ant-input-number-focused ~ decor { color: var(--main-color); }





cont .input-validation-error.ant-select:not(.ant-select-borderless),
cont .input-validation-error.ant-input-number,
cont .input-validation-error.ant-picker { border: none; box-shadow: none; }

cont input.input-validation-error,
cont input.input-validation-error ~ i,
cont textarea.input-validation-error { box-shadow: none }

cont .input-validation-error ~ decor border { box-shadow: inset 0 0 0 1.52px rgb(240 80 80); }
cont .input-validation-error ~ decor label line { box-shadow: inset 0 1.5px 0 0 rgb(240 80 80); }
cont .input-validation-error ~ decor label text { color: rgb(240 80 80); }



cont .ant-select-selection-item,
cont .ant-select-selection-placeholder { padding: 0 calc(var(--point)*3) 0 calc(var(--point)*1.5) !important; }

cont .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input,
cont .ant-select.ant-select-multiple .ant-select-selection-overflow,
cont .ant-picker-input input,
cont .ant-input-number-input-wrap input { padding-left: calc(var(--point)*1.5)!important;}


/*cont type set*/

cont > .ant-select,
cont > .ant-picker { min-height: var(--material-point)!important; line-height: var(--material-point) !important; }
cont > .ant-input-number { height: var(--material-point) !important;}


cont[compact] > .ant-select,
cont[compact] > .ant-picker {min-height: var(--material-line-point) !important; line-height: var(--material-line-point) !important; }
cont[compact] > .ant-input-number {height: var(--material-line-point) !important; }




/*input:disabled,
 textarea:disabled,
.input_cont input:disabled ~ i { opacity: .7 }

input[disabled] ~ box,
input[disabled] ~ text { opacity: .5; cursor: default }*/


/*line field*/






/*ant-picker-dropdown*/

.ant-picker-dropdown { font-size: inherit; line-height: inherit; color: inherit; font-family: var(--main-font); }
.ant-picker-panel-container{}
.ant-picker-panel{}
.ant-picker-date-panel { }


.ant-picker-header {padding:0; }
.ant-picker-header-view {line-height:30px; }

.ant-picker-header button { line-height: 30px; font-size: inherit; min-width: 0; color: inherit; }
.ant-picker-header button > span { transform: none; display: flex; align-items: center; justify-content: center; width: auto; height: auto; }
    .ant-picker-header button > span:after {border:none;top:auto;left:auto; }
.ant-picker-header button > span:after { /*background: green;*/ content: ''; width: calc(var(--point)*3); height: calc(var(--point)*3); font-size: 25px; flex-shrink: 0; display: flex; -ms-font-feature-settings: "liga", "dlig"; -o-font-feature-settings: "liga", "dlig"; font-feature-settings: "liga", "dlig", "liga", "clig"; font-variant-ligatures: common-ligatures; text-rendering: optimizeLegibility; font-family: icon; display: flex; display: -webkit-flex; align-items: center; -ms-flex-align: center; justify-content: center; -ms-flex-pack: center; font-weight: 400; font-style: normal; -webkit-user-select: none; user-select: none; text-transform: lowercase; position: relative; z-index: 9; overflow: hidden; text-decoration: none; pointer-events: none; }
.ant-picker-header button > span:before {display:none;}



        .ant-picker-super-prev-icon:after { content: "full_left"!important; }
        .ant-picker-prev-icon:after { content: "left" !important; }
        .ant-picker-next-icon:after { content: "right" !important; }
        .ant-picker-super-next-icon:after { content: "full_right" !important; }




.ant-picker-date-panel .ant-picker-content { width:100%; }

.ant-picker-decade-panel .ant-picker-content, 
.ant-picker-year-panel .ant-picker-content, 
.ant-picker-quarter-panel .ant-picker-content, 
.ant-picker-month-panel .ant-picker-content{width:100%;height:100%;}


.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel { width: 220px; height: 250px; }


.ant-picker-body { }

   


         /*ant-picker-time-panel*/
        .ant-picker-time-panel { width: 200px; }

        .ant-picker-ranges { justify-content: space-between; min-height: var(--area); width: 100%; position: relative; background: var(--background); align-items: center; flex-shrink: 0; box-shadow: 0 0 0 1px var(--border-color); user-select: none; display: flex; padding: 0 10px; z-index: 99; }
        .ant-picker-now .ant-picker-now-btn { min-width: 60px; display: flex; align-items: center; justify-content: center; border-radius: 30px; }
        .ant-picker-now .ant-picker-now-btn:hover { background-image: var(--over-color); }

        .ant-picker-ok button { border-radius: 30px; min-width: 60px; line-height: 30px; height: 30px; padding: 0 5px; border: none; }
        .ant-picker-ok button span { display: block; }

        .ant-picker-body { }

        .ant-picker-now .ant-picker-now-btn { min-width: 60px; display: flex; align-items: center; justify-content: center; border-radius: 30px; }

        .ant-picker-ok button { border-radius: 30px; min-width: 60px; line-height: 30px; height: 30px; padding: 0 5px; border: none; }
        .ant-picker-ok button span { display: block; }

        .ant-picker-ranges { justify-content: space-between; min-height: var(--area); width: 100%; position: relative; background: var(--background); align-items: center; flex-shrink: 0; box-shadow: 0 0 0 1px var(--border-color); user-select: none; display: flex; padding: 0 10px; z-index: 99; }
        .ant-picker-time-panel-cell-inner { line-height: 30px !important; height: 30px !important; padding: 0 !important; padding-right: 15px; text-align: center;font-family:var(--main-font); }
        .ant-picker-time-panel-cell { display: flex; align-items: center; justify-content: center; }

        .ant-picker-time-panel-column,
        .ant-picker-time-panel-column:hover { overflow: scroll; }








.ant-picker-body { flex-grow: 1; }

.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body,
.ant-picker-decade-panel .ant-picker-body,
.ant-picker-date-panel .ant-picker-body { padding: 5px; }

.ant-picker-content th{min-width:0px!important;width:30px!important;}
.ant-picker-content td { min-width:30px;padding:0px; }
.ant-picker-cell::before{display:none;}
.ant-picker-cell .ant-picker-cell-inner { min-width: 0px; width: 30px; height: 30px; line-height: 30px; border-radius: 0px; display: flex; align-items: center; justify-content: center; margin: auto; }
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{border-radius:0;}
.ant-picker-cell-selected { }

.ant-picker-footer { }
/**/


.rc-virtual-list-scrollbar { position: static !important; }
.rc-virtual-list-scrollbar-thumb { display: none !important; }
.rc-virtual-list-holder { overflow: overlay !important; }




.ant-select-item { height: 30px; line-height: 30px; display: flex; align-items: center; min-height: 0; font-size: inherit; }
.ant-select-item-option-selected{font-weight:inherit!important;}
.ant-select-item-option-state { display: flex; align-items: center; justify-content: center; }
.ant-select-item-option-state .anticon {display:flex;align-items:center;justify-content:center;}




/*ant tooltip*/
.ant-tooltip { color: inherit; line-height: inherit; font-size: inherit; }
.ant-tooltip-content { }
/*.ant-tooltip .ant-tooltip-arrow { display: none; }*/
.ant-tooltip-arrow-content { }
.ant-tooltip-inner { padding: 5px 10px; min-height: 30px; display: flex; flex-direction:column; align-items: flex-start; justify-content: center; border-radius: 3px; background: var(--tip); }



/*.ant-message*/

.ant-message { position: fixed; height: 100%; width: 100%;display:flex;align-items:center;justify-content:center; }
.ant-message-notice { max-width: 320px; text-align: left; }
.ant-message-notice-content { border-radius: 0; padding: 10px 15px 10px 5px; }
.ant-message-custom-content { display: flex; /*align-items: center;*/ }
    .ant-message-custom-content.ant-message-success { }


.ant-message .anticon { top: 0; margin-right: 5px; color: inherit; }
.ant-message .anticon:after { }
.anticon.anticon-check-circle { }
.ant-message .anticon span { }


.ant-message-warning .anticon {color: var(--orange-color); }
.ant-message-success .anticon {color: var(--private-color);}
.ant-message-error .anticon {color: var(--error-color);}

.ant-message-warning .anticon-exclamation-circle:after { content: "warning" }
.ant-message-success .anticon-check-circle:after { content: "success" }
.ant-message-error .anticon-check-circle:after { content: "error" }



/*breadcrumb*/
.ant-breadcrumb li{display:flex;}
.ant-breadcrumb { font-size: inherit; /*margin: 10px 0;*/ min-height: 20px; line-height: 20px; display: flex; align-items: center; }
.ant-breadcrumb span { line-height: inherit; display: flex; align-items: center; }
span.ant-breadcrumb-separator { font-size: 0; }
span.ant-breadcrumb-separator:after { content: "right"; font-size: 11px; width: calc(var(--point)*2); height: calc(var(--point)*2); flex-shrink: 0; display: flex; font-feature-settings: "liga", "dlig"; font-variant-ligatures: common-ligatures; text-rendering: optimizeLegibility; font-family: icon; display: flex; align-items: center; justify-content: center; font-size: calc(var(--point)*2.3); font-weight: 400; font-style: normal; user-select: none; text-transform: lowercase; position: relative; z-index: 9; overflow: hidden; text-decoration: none; pointer-events: none; }
/**/


.ant-table-expanded-row .ant-table .ant-table-body {padding: 20px 0px 20px 30px !important; }



/*.ant-popover*/

.ant-popover { }
.ant-popover-content { }
.ant-popover-arrow { }
.ant-popover-arrow-content { --antd-arrow-background-color: var(--main-color-dark); }
.ant-popover-inner { border-radius: 0; background-color: var(--main-color-dark); }
.ant-popover-inner-content { padding: 0; }
.ant-popover-inner-content input { background: var(--ant-input-color); }

.popover .form_fields { padding: 2px; }
.popover-menu { background-color: var(--main-color); position: absolute; opacity: 0; transform: translate(0%, -30px); }
.popover:hover .popover-menu, .popover:focus .popover-menu, .popover:focus-visible .popover-menu,
.popover:hover .popover-menu:hover, .popover:focus .popover-menu:hover, .popover:focus-visible .popover-menu:hover { opacity: 1; transform: translate(-100%, -30px); transition: transform 0ms ease-in-out, opacity 600ms ease-in-out; }

/* stylelint-enable */